<template>
  <div class="pa-4">

    <div v-if="getRequestLoadStatus !== 2">
      <v-skeleton-loader
          class="mt-5"
          type="avatar"
      ></v-skeleton-loader>
      <v-skeleton-loader
          class="mt-5"
          type="paragraph"
      ></v-skeleton-loader>
    </div>

    <div v-else>
      <v-row>
        <v-col cols="12" md="12">
          <div
              :style="{'background-image': 'url('+getRequest.user_profile_picture+')'}"
              class="avatar"
          ></div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <div class="text--secondary text-uppercase label">First Name</div>
          <div>{{ getRequest.user_first_name }}</div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="text--secondary text-uppercase label">Last Name</div>
          <div>{{ getRequest.user_last_name }}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <div class="text--secondary text-uppercase label">Email</div>
          <div>{{ getRequest.user_email }}</div>
        </v-col>
        <v-col cols="12" md="6">

        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <div class="text--secondary text-uppercase label">PHONE NUMBER</div>
          <div>{{ getRequest.user_phone }}</div>
        </v-col>
        <v-col cols="12" md="6">

        </v-col>
      </v-row>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "Recipient",
  computed: {
    ...mapGetters(['getRequestLoadStatus', 'getRequest']),
  },
}
</script>
<style scoped>
.avatar {
  height: 174px;
  width: 174px;
  background-color: #929292;
  border-radius: 100px;
  background-size: cover;
  margin: 0 auto;
}
.label {
  font-size: 13px;
}
</style>